<template>
  <div>
    <form novalidate class="md-layout md-alignment-top-center" @submit.prevent="validatePlate">
      <md-card class="md-layout-item md-size-50 md-small-size-100">
        <md-card-header>
          <div class="md-title">Search by Plate</div>
        </md-card-header>
        <md-card-content>
          <md-field md-clearable :class="getValidationClass('plate')">
            <label for="plate">License Plate</label>
            <md-input name="plate" id="plate" v-model="form.plate" maxlength="20" required />
            <span class="md-error" v-if="!$v.form.plate.required">The License Plate is required</span>
            <span class="md-error" v-else-if="!$v.form.plate.minlength">Invalid License Plate</span>
            <span class="md-error" v-else-if="!$v.form.plate.maxlength">Invalid License Plate</span>
          </md-field>
          <div v-if="results.length > 0">
            <md-table v-model="results" @md-selected="onSelect">
              <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="single" style="color: blue;">
                <md-table-cell md-label="Ref Number">{{ item.noticeNumber }}</md-table-cell>
                <md-table-cell md-label="Plate">{{ item.plate }}&nbsp;({{ item.state }})</md-table-cell>
                <md-table-cell md-label="Amount Due">{{ item.amount | currency }}</md-table-cell>
                <md-table-cell md-label="Owed To">{{ item.company }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </md-card-content>
        <md-progress-bar md-mode="indeterminate" v-if="sending" />
        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="sending">Search</md-button>
        </md-card-actions>
      </md-card>
      <md-snackbar :md-active.sync="notFound">The plate {{ form.plate }} has no matching results.</md-snackbar>
    </form>
    <md-dialog-confirm
      :md-active.sync="dialog"
      md-title="Pay or Dispute?"
      md-content="Would you like to pay this notice or dispute it?"
      md-confirm-text="Pay"
      md-cancel-text="Dispute"
      @md-cancel="goToDispute"
      @md-confirm="goToPay" />
    <md-dialog-alert
      :md-active.sync="showNote"
      md-title="Special Note"
      :md-content="'This notice has been paid in full.  If you received a letter dated '+badLetterStr+', please disregard that communication.  Thank you.'" />
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import axios from 'axios'

export default {
  name: 'searchView',
  data () {
    return {
      form: {
        plate: this.$store.getters.plate
      },
      sending: false,
      notFound: false,
      selected: null,
      dialog: false,
      showNote: false
    }
  },
  computed: {
    results () {
      return this.$store.getters.results
    },
    badLetterStr () {
      return (this.selected?.badLetter) ? this.selected.badLetter : ''
    }
  },
  methods: {
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    validatePlate () {
      this.$v.$touch()
      this.form.plate = this.form.plate.toUpperCase().replace(/[^A-Z0-9]/g, '')
      if (!this.$v.$invalid) {
        this.runSearch()
      }
    },
    async runSearch () {
      this.sending = true
      this.$store.commit('setPlate', this.form.plate)
      await this.runSearchApi()
      this.notFound = (this.results.length === 0)
      this.sending = false
    },
    async runSearchApi () {
      const { data } = await axios({
        method: 'GET',
        url: `https://parkprompt.azurewebsites.net/api/websearch?plate=${encodeURIComponent(this.form.plate)}`
        // url: `http://localhost:7071/api/websearch?plate=${encodeURIComponent(this.form.plate)}`
      })
      for (const v of data) {
        if (v.status !== 'open') continue
        const isCollectPark = (v.collectionAgentId == '1')
        if (v.collectionAgentId && !isCollectPark) continue
        if (!(v.canPay || v.canDispute || isCollectPark)) continue
        this.$store.commit('addResult', {
          srcId: v.id,
          noticeNumber: v.refNumber,
          amount: parseFloat(v.amountDue),
          company: ((v.collectionAgentName) ? v.collectionAgentName : v.clientName),
          plate: v.plate,
          state: v.state,
          paymentUrl: v.paymentUrl,
          canPay: (((v.canPay || isCollectPark) && v.client.ccKey) || v.paymentUrl),
          canDispute: (v.canDispute || isCollectPark),
          image1: (v.photos.length >= 1) ? v.photos[0] : null,
          image2: (v.photos.length >= 2) ? v.photos[1] : null,
          client: v.client
        })
      }
    },
    onSelect (item) {
      if (!item) return
      this.selected = item
      this.$store.commit('setSelected', item)

      if (item.badLetter) {
        this.showNote = true
      } else if (item.canPay && item.canDispute) {
        this.dialog = true
      } else if (item.canPay) {
        this.goToPay()
      } else if (item.canDispute) {
        this.goToDispute()
      }
    },
    goToPay () {
      this.dialog = false
      if (this.selected.client.ccKey) {
        if (this.selected.client.ccType === 'Stripe') {
          window.location = `https://stripe.paymyparkingfee.com/pay?v=${this.selected.srcId}`
        } else {
          this.$router.push('/pay')
        }
      } else if (this.selected.paymentUrl) {
        window.location = this.selected.paymentUrl
      }
    },
    goToDispute () {
      this.dialog = false
      window.location = `https://dispute.paymyparkingfee.com/?id=${this.selected.srcId}&number=${this.selected.noticeNumber}`
    }
  },
  mounted () {
    if (window.location.search) {
      const parms = new URLSearchParams(window.location.search)
      this.form.plate = parms.get('plate')
    }
    if (this.form.plate) {
      this.validatePlate()
    }
  },
  validations: {
    form: {
      plate: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(20)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .md-field:last-child {
    margin-bottom: 40px;
  }
</style>
